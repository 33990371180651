import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center vertical-flow__form__step contact-container" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "pb-5 text-left contact-container__contact" }
const _hoisted_4 = { class: "contact-container__contact__type__icon" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "contact-container__contact__type__icon" }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", {
        class: "mb-4",
        textContent: _toDisplayString(_ctx.model.title)
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "contact-container__contact__type",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal(true)))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _ctx.model.callIcon.url,
              alt: ""
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", {
            textContent: _toDisplayString(_ctx.model.callFooterText)
          }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("button", {
          class: "contact-container__contact__type",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModal(false)))
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: _ctx.model.emailIcon.url,
              alt: ""
            }, null, 8, _hoisted_8)
          ]),
          _createElementVNode("div", {
            textContent: _toDisplayString(_ctx.model.emailFooterText)
          }, null, 8, _hoisted_9)
        ])
      ])
    ])
  ]))
}