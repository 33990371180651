import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { nextTick } from 'vue';
import VueScrollTo from 'vue-scrollto';
import { mapState } from 'vuex';
import VerticalStepComponent from './VerticalStepComponent.vue';
import { PROPOSAL_INFO_ERROR, PROPOSAL_LOADED } from '@/store/modules/ProposalContext';
import { isInView } from '@/services/uiService';
import { getInsuranceCompanies, getProposal, submitConsent, submitProposal } from '@/services/apiService';
import ModelMapper from './ModelMapper';
import { formatterDigit } from '@/services/FormatService';
import { updateConsentFromApi } from '@/services/consentService';
import { isDevelop } from '@/services/environmentService';
import { trackStep } from '@/services/TrackService';
import { Log } from '@/appinsights/logging/log';

@Options({
	name: 'VerticalContainerComponent',
	components: {
		VerticalStepComponent,
	},
	computed: mapState<any>({
		party: (state) => state.ProposalContext.party,
		quote_id: (state) => state.ProposalContext.quote_id,
		price: (state) => state.ProposalContext.price,
		proposalSteps: (state) => store.state.ProposalContext.proposalSteps,
	}),
	props: {
		model: Object,
	},
})
export default class VerticalContainerComponent extends Vue {
	private submitTime = 'NA';
	public model: any;

	public party!: any;
	public quote_id!: string;
	public price!: number;
	public proposalSteps!: Map<string, any>; // KLK types

	public renderPage = false;
	public showPage = false;
	private waitActive = true;
	private activeStep = undefined;

	public visibleError = false;
	public loadError = null;

	public created() {
		window.addEventListener('keyup', this.moveOn);
	}
	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.activeStep.isValid) {
			this.nextStep(this.activeStep);
		}
	}

	public async mounted() {
		try {
			const query = this.$route.query;
			if (query) {
				store.state.ProposalContext.quote_id = query.quote as string;
				store.state.ProposalContext.customerNo = query.customer_no as string;
				store.state.ProposalContext.signatureId = query.signature as string;
			}

			const proposal = await getProposal();
			if (isDevelop && proposal?.status === 'TEST_DATA') {
				this.model.mockedDataLoaded = true;
				store.state.ProposalContext.quote_id = 'MOCK ' + proposal.data.quote_id;
				store.state.ProposalContext.customerNo = 'MOCK custNo';
				store.state.ProposalContext.signatureId = 'dummy signatureID';
			}

			const insuranceCompanies = await getInsuranceCompanies();
			store.state.ProposalContext.insuranceCompanies = insuranceCompanies?.data?.insurance_companies;

			store.state.ProposalContext.originalRequest = proposal.data;

			const modelMapper: ModelMapper = new ModelMapper(proposal, this.model);
			await modelMapper.mapProposalSteps();

			// get user consent choses from API - no need to wait
			updateConsentFromApi();

			this.activeStep = store.getters.getActiveProposalStep(undefined);

			this.renderPage = true;
			setTimeout(() => {
				store.state.showSpinner = false;
				// make sure activeStep is not loaded immediately - enables transition
				this.waitActive = false;
				this.showPage = true;
			}, 100);

			// wait for pageLoad tracking
			setTimeout(() => {
				trackStep(this.activeStep.title, this.activeStep.title);
			}, 2000);
		} catch (error) {
			Log.exception('Error mounting proposal', error, { quoteId: store.state.ProposalContext.quote_id });

			store.state.showSpinner = false;
			nextTick(() => {
				this.loadError = 'Tilbud kunne ikke hentes';
			});
		} finally {
			await store.dispatch(PROPOSAL_LOADED);
		}
	}

	public isActiveStep(step: any): boolean {
		return step.id === this.activeStep?.id && !this.waitActive;
	}

	public isValid(step: any): boolean {
		return step.isValid;
	}

	public async gotoStep(step: any): Promise<void> {
		// KLK await updateConsentFromApi(); when consentBlock is pressed on
		// KLK await submitConsentToApi(); when consentBlock is currentStep and customer is leaving

		const previousStepsValid: boolean = store.getters.getIsPreviousProposalStepsValid(step.id);
		if (previousStepsValid) {
			this.activeStep = step;
			trackStep(
				this.activeStep.contentType === 'PolicyBlock' ? this.activeStep.title : undefined,
				this.activeStep.title
			);

			this.scrollMeTo(this.activeStep);
			this.visibleError = false;
			this.reloadLoop();
		} else {
			store.dispatch(PROPOSAL_INFO_ERROR, true);
			this.visibleError = true;
		}
	}
	public async nextStep(step: any) {
		if (!step.isValid) {
			store.dispatch(PROPOSAL_INFO_ERROR, true);
			this.visibleError = true;
			return;
		}

		const active = store.getters.getActiveProposalStep(step.id);

		if (!active) {
			trackStep(undefined, 'Signicat');

			this.visibleError = false;
			// all steps are valid
			store.state.showSpinner = true;
			let time = new Date().getTime();
			await submitConsent();
			const patchRes = await submitProposal();
			time = (new Date().getTime() - time) / 1000;
			this.submitTime = formatterDigit.format(time) + ' sek.';
			if (patchRes?.status === 200 && patchRes.data.signicat_signing_url) {
				location.href = patchRes.data.signicat_signing_url;
			} else {
				setTimeout(() => {
					store.state.modal = this.testSignicatModal(false);
					store.state.showSpinner = false;
				}, 100);
			}
			return;
		}

		this.activeStep = active;
		trackStep(
			this.activeStep.contentType === 'PolicyBlock' ? this.activeStep.title : undefined,
			this.activeStep.title
		);
		this.visibleError = false;
		this.scrollMeTo(this.activeStep);
		this.reloadLoop();
	}

	private testSignicatModal(patchRes) {
		const testReceiptUrl = `/receipt-page?quote=${store.state.ProposalContext.quote_id}&customer_no=${store.state.ProposalContext.customerNo}&signature=${store.state.ProposalContext.signatureId}`;
		// setup fail modal as default
		const modal: any = {};
		// modal.modalId = '12';
		modal.show = true;
		modal.id = 'proposal'; // never changes
		modal.track = false;
		modal.trackToken = undefined;
		modal.content = `
		<div style="background-color: ${patchRes ? 'lightgreen' : 'red'}; width: 100%; border-radius: 8px; 
		height: 44px;
		padding: 8px;">Brugerdata sendt til TIA Success: ${patchRes} (${this.submitTime})</div>
		<div style="display: flex; justify-content: center;">
		<ul class="text-left">
            <li>Underskriv hos Signicat</li>
            <li>Gå til kvittering</li>
            </ul></div>`;
		modal.btnStyle = 'Button Button__secondary';
		modal.btnLabel = 'Gå til kvittering';
		modal.btnIcon = undefined;
		modal.btnAction = testReceiptUrl;
		modal.btnSecondStyle = 'Button Button__tertiary';
		modal.btnSecondLabel = 'Fortryd';
		modal.btnInlineLabel = undefined;
		modal.btnInlineAction = undefined;
		modal.btnInlinestyle = undefined; //'btn-solid-secondary';
		modal.title = 'Underskrift hos Signicat';
		modal.redirect = undefined; // '/'
		return modal;
	}

	public scrollMeTo(step: any) {
		if (!step?.id) {
			return;
		}
		setTimeout(() => {
			if (!isInView(`_${step.id}`)) {
				VueScrollTo.scrollTo(`#_${step.id}`, 300, { easing: 'ease-out', offset: -15 });
			}
		}, 1200);
	}
	private reloadLoop() {
		this.renderPage = !this.renderPage;
		this.renderPage = !this.renderPage;
	}
}
