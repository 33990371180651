import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'CheckboxComponent',
	props: {
		name: String,
		id: String,
		labelledby: { type: String, default: undefined },
		inputValue: { type: String, default: undefined },
		preSelect: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		change: Boolean,
		visibleError: Boolean,
		validationErrorText: String,
		label: String,
		postLabel: String,
		tooltip: String,
	},
})
export default class CheckboxComponent extends Vue {
	name: string;
	id: string;
	labelledby?: string;
	inputValue?: string;
	preSelect?: boolean;
	disabled: boolean;
	change: boolean = false;
	visibleError: boolean;
	validationErrorText: string;
	label: string;
	tooltip: string;
	postLabel: string;

	// LIFECYCLE
	public created() {
		if (this.preSelect !== undefined) {
			this.change = this.preSelect;
		}
	}

	public get showError() {
		return this.visibleError && !this.change;
	}

	// METHODS
	public changeItem() {
		this.change = !this.change;
		const item = (this as any).$refs[this.name + this.id];
		(item as HTMLInputElement).checked = true;
		this.changed();
	}

	public changed() {
		(this as any).$emit('CheckboxComponent', {
			name: this.name,
			id: this.id,
			value: this.inputValue !== undefined ? this.inputValue : this.change,
			checked: this.change,
		});
		(this as any).$refs[this.name + this.id].blur();
	}
}
