import { Options, Vue } from 'vue-class-component';
import PageTitleComponent from '@/sharedcomponents/pageComponents/pageTitle/PageTitleComponent.vue';
import { ReceiptPage } from '@/cms/definitions/content-types';
import store from '@/store/store';
import CookieFooter from '@/sharedcomponents/pageComponents/cookieFooter/cookieFooter.vue';
import VerticalStepComponent from './VerticalStepComponent.vue';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { UsabillaInPageBlock } from '@/cms/definitions/content-types';

import ModelMapper from './ModelMapper';
import { handleIdleLogout, isDevelop } from '@/services/environmentService';
import { getProposal } from '@/services/apiService';

@Options({
	name: 'ReceiptComponent',
	components: {
		PageTitleComponent,
		VerticalStepComponent,
		UsabillaInPageComponent,
		CookieFooter,
	},
	props: {
		model: Object,
	},
})
export default class ReceiptComponent extends Vue {
	public model: ReceiptPage; // epi contentModel
	public usabillaBlock: UsabillaInPageBlock = null; // epi contentModel
	public usabillaBlockLeaveIntent: any = null;
	public renderPage = false;
	public headline = '...';
	public policy: any;
	public proposalIdLabel: string;
	public proposalId: string;
	public isDevelop = isDevelop;

	public beforeCreate() {
		handleIdleLogout(
			store.getters.getThemeContext === 'codan' ? 'https://www.codan.dk' : 'https://www.almbrand.dk',
			5
		);
	}

	public async beforeMount() {
		store.state.showSpinner = true;
		this.headline = this.model.headline;

		const query = this.$route.query;
		if (query) {
			store.state.ProposalContext.quote_id = query.quote as string;
			store.state.ProposalContext.customerNo = query.customer_no as string;
			store.state.ProposalContext.signatureId = query.signature as string;
		}

		const proposal = await getProposal();
		if (isDevelop && proposal?.status === 'TEST_DATA') {
			this.model.mockedDataLoaded = true;
			store.state.ProposalContext.quote_id = 'MOCK ' + proposal.data.quote_id;
			store.state.ProposalContext.customerNo = 'MOCK custNo';
			store.state.ProposalContext.signatureId = 'dummy signatureID';
		}

		store.state.ProposalContext.originalRequest = proposal.data;
		const modelMapper: ModelMapper = new ModelMapper(proposal, this.model);
		this.policy = modelMapper.getReceiptPolicyDetails();
		this.proposalIdLabel = 'Kvittering for tilbudsnummer: '; // KLK CMS
		this.proposalId = store.state.ProposalContext.quote_id;
		this.setupUsabilla();

		this.renderPage = true;
		store.state.showSpinner = false;
	}

	private setupUsabilla(): void {
		const items = this.model.contentArea?.items;
		if (items?.length > 0) {
			items.forEach((block) => {
				if (block?.content.contentType === 'UsabillaInPageBlock') {
					this.usabillaBlock = block.content;
				}
			});
		}
	}

	public get showSpinner() {
		return store.state.showSpinner;
	}

	public nextStep() {
		store.state.showSpinner = true;
		location.href = store.getters.getThemeContext === 'codan' ? 'https://www.codan.dk' : 'https://www.almbrand.dk';
	}
}
