
	import { defineComponent } from 'vue';
	import { defaultConfig } from '@/cms/api/ApiConfig';
	import store from '@/store/store';

	export default defineComponent({
		data() {
			return {
				firstLoad: true,
			};
		},
		computed: {
			canonicalUrl() {
				return store.state.epiContent.model?.canonicalUrl;
			},
			themeName: () => {
				return store?.getters?.getThemeContext;
			},
		},
		methods: {
			isTouchDevice() {
				const isTouchDevice =
					'ontouchstart' in window ||
					navigator.maxTouchPoints > 0 ||
					// eslint-disable-next-line
					(window as any).navigator.msMaxTouchPoints > 0;
				store.commit('setDeviceType', isTouchDevice);
				return isTouchDevice;
			},
			getScrollbarWidth() {
				if (this.isTouchDevice()) {
					return;
				}
				const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
				document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);
			},
			setCanonicalUrl() {
				const tag = document.querySelector('link[rel="canonical"]');
				if (tag) tag.setAttribute('href', this.canonicalUrl.url);
			},
		},
		mounted() {
			const element = document.getElementById('app');

			const domainUrl = element?.getAttribute('data-domain') ?? '';

			const contentDeliveryApiUri = element?.getAttribute('data-contentdelivery-url') ?? '';
			defaultConfig.apiUrl = domainUrl + contentDeliveryApiUri;
			defaultConfig.baseUrl = domainUrl;
			defaultConfig.selectAllProperties = true;
			defaultConfig.expandAllProperties = true;

			document.onreadystatechange = () => {
				// wait for DOM to render
				if (document.readyState === 'complete') setTimeout(this.getScrollbarWidth, 100);
			};
		},
	});
