import store from '@/store/store';
import { CheckField, Consent, ConsentResponse } from '@/types/types';
import { getConsent } from './apiService';
import { PROPOSAL_STEPS } from './FieldService';
import { Log } from '@/appinsights/logging/log';

export const updateConsentFromApi = async (): Promise<void> => {
	const inputFields = store.state.ProposalContext.proposalSteps.get(PROPOSAL_STEPS.consentBlock)?.inputFields;
	const consentResponse: { data: ConsentResponse; status: any } = await getConsent();

	if (consentResponse?.status === 'TEST_DATA') {
		Log.exception('fail consent TEST_DATA LOADED', undefined, { quoteId: store.state.ProposalContext.quote_id });
	} else if (consentResponse?.status !== 200) {
		Log.exception('fail consent', undefined, { quoteId: store.state.ProposalContext.quote_id });
		// KLK ERROR
		return;
	}
	const consentData: ConsentResponse = consentResponse.data;

	consentData.consents.forEach((consent: Consent) => {
		const field = inputFields.find((field: CheckField) => field.id === consent.consent_type);
		if (field) {
			field.value = consent.is_accepted;
			// don't use consent.consent_text, since the text for all consents is identical and way too large
		} else {
			Log.exception('Field ' + consent.consent_type + ' is not present in CMS', undefined, {
				quoteId: store.state.ProposalContext.quote_id,
			});
		}
	});
};

export const createConsentBody = (): { consents: Array<Consent> } => {
	const inputFields = store.state.ProposalContext.proposalSteps.get(PROPOSAL_STEPS.consentBlock)?.inputFields;

	const consents: Array<Consent> = [];
	const consentBody = { consents };

	inputFields.forEach((field: CheckField) => {
		if (field?.masterId) {
			const consent: Consent = {
				consent_type: field.id,
				is_accepted: field?.value ? (field?.value as boolean) : false,
			};
			consents.push(consent);
		}
	});
	return consentBody;
};
