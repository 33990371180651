import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "page__version"
}
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.modelLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentTypeForPage(_ctx.model)), { model: _ctx.model }, null, 8, ["model"]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.version)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "page__version page__version-v",
            textContent: _toDisplayString(_ctx.version)
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}