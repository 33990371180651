import { CmsField, RadioButtonField } from '@/types/types';
import { getDate, isDateBefore } from './FormatService';
import store from '@/store/store';

export enum PROPOSAL_STEPS {
	previousBlock = 'previousBlock',
	paymentBlock = 'paymentBlock',
	consentBlock = 'consentBlock',
	receiptBlock = 'receiptBlock',
}

export enum POLICY_FIELDS {
	cancelExistingPolicyOptions = 'cancelExistingPolicyOptions',
	cover_start_date = 'cover_start_date',
	cancelExistingPolicy = 'cancelExistingPolicy',
	cancelExistingPolicyText = 'cancelExistingPolicyText',
	payment_frequency = 'payment_frequency',
	reg_no = 'reg_no',
	account = 'account',
	previous_company_id = 'previous_company_id',
	previous_policy_id = 'previous_policy_id',
	takeover_date = 'takeover_date',
	consent_payment = 'consent_payment',
	fees = 'fees',
	price = 'price',
}

export enum INPUT_TYPES {
	TextField = 'TextField',
	TextOnlyField = 'TextOnlyField',
	NumberField = 'NumberField',
	DatePickerField = 'DatePickerField',
	RadioButtonField = 'RadioButtonField',
	CheckField = 'CheckField',
	TextSearchField = 'TextSearchField',
}

export const customClickHandler = (stepId: string, fieldId: string, value) => {
	if (fieldId === POLICY_FIELDS.cancelExistingPolicyOptions && value) {
		// KLK const coverStartDate = getCancellationDate(stepId);
		// KLK  perhaps set date
	}
};

export const getCancellationDate = (stepId: string, inputFields?: CmsField[]): Date => {
	if (!inputFields) {
		inputFields = store.state.ProposalContext.proposalSteps.get(stepId).inputFields;
	}

	const coverStartDate = inputFields.find((field) => field.id === POLICY_FIELDS.cover_start_date);
	const cancelExistingPolicyOptions = inputFields.find(
		(field) => field.id === POLICY_FIELDS.cancelExistingPolicyOptions
	) as RadioButtonField;

	if (!coverStartDate || !cancelExistingPolicyOptions?.labels[0]) {
		return;
	}

	const coverStart = getDate(coverStartDate.value as string);
	const nearestCancellation = new Date();

	nearestCancellation.setMonth(nearestCancellation.getMonth() + 2);
	nearestCancellation.setDate(1);
	return coverStart && isDateBefore(nearestCancellation, coverStart) ? coverStart : nearestCancellation;
};
