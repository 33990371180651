import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import CmsPageComponentSelector from '@/cms/components/infrastructure/pageComponentSelector/PageComponentSelector.vue';
import store from '@/store/store';
import { UPDATE_MODEL_BY_URL } from '@/store/modules/epiContent';
import TestPage from './views/digitalAccept/pages/TestPage.vue';
import { isDevelop } from './services/environmentService';

const routes: Array<RouteRecordRaw> = [
	// {
	// 	name: 'page-component-selector-all',
	// 	path: '/:catchAll(.*)',
	// 	component: CmsPageComponentSelector,
	// },
	{
		name: 'page-component-selector',
		path: '/:pathMatch(.*)',
		component: CmsPageComponentSelector,
	},
];

if (isDevelop) {
	routes.push({
		name: 'TestPage',
		path: '/testPage',
		component: TestPage,
	});
}

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to, from, next) => {
	// URL is updated by vue-route-sync, and when time traveling with the
	// debugger we don't want to trigger a model commit as the model is already
	// part of the store holding the url update.
	const currentModelUrl = store.state.epiContent.model?.canonicalUrl.path;

	await updateModelByUrlFromFullPath(to, next, currentModelUrl);
});

async function updateModelByUrlFromFullPath(to, next, currentModelUrl) {
	const fullPath = to.fullPath.replace('#', '');

	if (currentModelUrl !== fullPath) {
		await store.dispatch(UPDATE_MODEL_BY_URL, fullPath);
		//don't render next page before model has been updated from url
	}
	next();
}

export default router;
