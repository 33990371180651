import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipComponent = _resolveComponent("TooltipComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      id: _ctx.id,
      class: "text-left info-block"
    }, [
      _createElementVNode("span", { innerHTML: _ctx.value }, null, 8, _hoisted_2),
      (_ctx.tooltip)
        ? (_openBlock(), _createBlock(_component_TooltipComponent, {
            key: 0,
            type: "icon",
            class: "page-component__popover text-left dynamic-position",
            "button-class": "popover__icon popover__icon__info",
            "inner-data": _ctx.tooltip
          }, null, 8, ["inner-data"]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1)
  ]))
}