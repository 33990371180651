import { ReceiptPage } from '@/cms/definitions/content-types';
import ReceiptComponent from '@/views/digitalAccept/ReceiptComponent.vue';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import store from '@/store/store';
import { setPageMetaData } from '@/services/environmentService';
import { initialPageTrack } from '@/services/TrackService';

@Options({
	name: 'ReceiptPageComponent',
	components: {
		ReceiptComponent,
	},
	props: {
		model: Object as PropType<ReceiptPage>,
	},
})
export default class ReceiptPageComponent extends Vue {
	model: ReceiptPage;

	beforeMount() {
		setPageMetaData(this.model);
	}

	mounted() {
		initialPageTrack();
	}

	public get showSpinner() {
		return store.state.showSpinner;
	}
}
