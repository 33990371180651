import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextOnlyFieldComponent = _resolveComponent("TextOnlyFieldComponent")!
  const _component_inputFieldComponent = _resolveComponent("inputFieldComponent")!
  const _component_RadioComponent = _resolveComponent("RadioComponent")!
  const _component_DatePickerComponent = _resolveComponent("DatePickerComponent")!
  const _component_checkboxComponent = _resolveComponent("checkboxComponent")!
  const _component_SearchComponent = _resolveComponent("SearchComponent")!
  const _component_AddressSearchComponent = _resolveComponent("AddressSearchComponent")!

  return (_ctx.renderPage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.step.inputFields, (field, inx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'field' + inx
          }, [
            _createVNode(_Transition, {
              name: "slide-simple",
              key: "field.id"
            }, {
              default: _withCtx(() => [
                (field.contentType === 'TextOnlyField')
                  ? _withDirectives((_openBlock(), _createBlock(_component_TextOnlyFieldComponent, {
                      key: 0,
                      class: "field.class",
                      tooltip: field.tooltip,
                      htmlValue: field.value
                    }, null, 8, ["tooltip", "htmlValue"])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024),
            _createVNode(_Transition, {
              name: "slide-simple",
              key: "field.id"
            }, {
              default: _withCtx(() => [
                (field.contentType === 'TextField')
                  ? _withDirectives((_openBlock(), _createBlock(_component_inputFieldComponent, {
                      class: "text-left",
                      key: field.id,
                      id: field.uiId,
                      type: field.type,
                      name: 'value',
                      placeholder: field.placeholder,
                      label: field.label,
                      tooltip: field.tooltip,
                      ___validateOnLoad: "!model.pristineStep.get(card.name)",
                      innerData: field,
                      readonlyValue: field.readonly ? field.value : undefined,
                      fieldValidatorType: "default",
                      required: field.required,
                      autocomplete: "off",
                      min: field.min,
                      max: field.max,
                      errorMsgOverride: field.errorMessage,
                      visibleError: _ctx.visibleError,
                      onInputChanged: ($event: any) => (_ctx.inputChange(field))
                    }, null, 8, ["id", "type", "placeholder", "label", "tooltip", "innerData", "readonlyValue", "required", "min", "max", "errorMsgOverride", "visibleError", "onInputChanged"])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024),
            _createVNode(_Transition, {
              name: "slide-simple",
              key: "field.id"
            }, {
              default: _withCtx(() => [
                (field.contentType === 'RadioButtonField' && !field.showAsBoxes)
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_RadioComponent, {
                        name: field.id,
                        id: field.uiId,
                        label: field.label,
                        tooltip: field.tooltip,
                        options: field.options,
                        labels: field.labels,
                        preSelect: field.value,
                        visibleError: _ctx.visibleError && !field.isValid,
                        validationErrorText: field.errorMessage || 'Vælg en mulighed',
                        onRadioComponent: ($event: any) => (_ctx.radioClick(field, $event))
                      }, null, 8, ["name", "id", "label", "tooltip", "options", "labels", "preSelect", "visibleError", "validationErrorText", "onRadioComponent"])
                    ], 512)), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024),
            _createVNode(_Transition, {
              name: "slide-simple",
              key: "field.id"
            }, {
              default: _withCtx(() => [
                (field.contentType === 'DatePickerField')
                  ? _withDirectives((_openBlock(), _createBlock(_component_DatePickerComponent, {
                      tooltip: field.tooltip,
                      id: field.Uid,
                      key: field.id,
                      label: field.label,
                      innerId: 'value',
                      innerData: field,
                      placeholder: field.placeholder,
                      dateLimitIncluded: field.isTodayValid,
                      validationRule: field.isValidBeforeToday ? 'BeforeToday' : 'AfterToday',
                      maxLowerLimitYears: field.maxLowerLimitYears !== 0 ? field.maxLowerLimitYears : undefined,
                      maxUpperLimitYears: field.maxUpperLimitYears !== 0 ? field.maxUpperLimitYears : undefined,
                      readonlyValue: field.readonly ? field.value : undefined,
                      visibleError: _ctx.visibleError,
                      errorMsg: field.errorMessage,
                      onDateSelected: ($event: any) => (_ctx.dateSelected(field, $event))
                    }, null, 8, ["tooltip", "id", "label", "innerData", "placeholder", "dateLimitIncluded", "validationRule", "maxLowerLimitYears", "maxUpperLimitYears", "readonlyValue", "visibleError", "errorMsg", "onDateSelected"])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024),
            _createVNode(_Transition, {
              name: "slide-simple",
              key: "field.id"
            }, {
              default: _withCtx(() => [
                (field.contentType === 'CheckField')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(field.class)
                    }, [
                      (_openBlock(), _createBlock(_component_checkboxComponent, {
                        key: field.id,
                        id: field.uiId,
                        name: 'value',
                        label: field.label,
                        postLabel: field.postLabel,
                        tooltip: field.tooltip,
                        preSelect: field.value,
                        inputValue: field.id,
                        visibleError: _ctx.visibleError && !field.isValid,
                        onCheckboxComponent: ($event: any) => (_ctx.checkboxEvent(field, $event)),
                        validationErrorText: field.errorMessage
                      }, null, 8, ["id", "label", "postLabel", "tooltip", "preSelect", "inputValue", "visibleError", "onCheckboxComponent", "validationErrorText"]))
                    ], 2)), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024),
            _createVNode(_Transition, {
              name: "slide-simple",
              key: "field.id"
            }, {
              default: _withCtx(() => [
                (field.contentType === 'TextSearchField' || field.contentType === 'DropDownField')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", {
                      key: field.id
                    }, [
                      _createVNode(_component_SearchComponent, {
                        id: field.uiId,
                        isDropdownOnly: field.isDropdownOnly,
                        defaultValue: field.value,
                        autocompleteId: field.uiId,
                        placeholder: field.placeholder,
                        label: field.label,
                        tooltip: field.tooltip,
                        searchByCleanedInput: true,
                        resultList: field.labels,
                        optionsList: field.options,
                        isValid: field.value !== undefined,
                        visibleError: _ctx.visibleError,
                        readonlyValue: field.readonly ? field.value : undefined,
                        onSearch: ($event: any) => (_ctx.searchSelected(field, $event))
                      }, null, 8, ["id", "isDropdownOnly", "defaultValue", "autocompleteId", "placeholder", "label", "tooltip", "resultList", "optionsList", "isValid", "visibleError", "readonlyValue", "onSearch"])
                    ])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024),
            _createVNode(_Transition, {
              name: "slide-simple",
              key: "field.id"
            }, {
              default: _withCtx(() => [
                (field.contentType === 'AddressSearchField')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", {
                      key: field.id
                    }, [
                      _createVNode(_component_AddressSearchComponent, {
                        id: field.uiId,
                        defaultValue: field.value,
                        autocompleteId: field.id,
                        placeholder: field.placeholder,
                        label: field.label,
                        isOnlyZipSearch: field.zipOnly,
                        isValid: _ctx.addressValue !== undefined,
                        visibleError: _ctx.visibleError,
                        readonlyValue: field.readonly ? field.value : undefined,
                        onAddressSearch: _ctx.searchAddress
                      }, null, 8, ["id", "defaultValue", "autocompleteId", "placeholder", "label", "isOnlyZipSearch", "isValid", "visibleError", "readonlyValue", "onAddressSearch"])
                    ])), [
                      [_vShow, field.isVisible]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}