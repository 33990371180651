import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'LabelComponent',
	components: {},
	props: {
		idFor: String,
		label: String,
		tooltip: String,
		readonly: { type: Boolean, default: false },
		isHtmlLabel: { type: Boolean, default: true },
	},
})
export default class InputFieldComponent extends Vue {
	idFor?: string;
	label: string;
	tooltip?: string;
	readonly: boolean;
	isHtmlLabel: boolean;
}
