import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "InputTextField__wrapper"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "InputTextField__container" }
const _hoisted_5 = ["id", "value", "readonly"]
const _hoisted_6 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelComponent = _resolveComponent("LabelComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.type !== 'hidden')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LabelComponent, {
            label: _ctx.label,
            tooltip: _ctx.tooltip,
            isHtmlLabel: true,
            readonly: !!_ctx.readonlyValue,
            idFor: _ctx.id
          }, null, 8, ["label", "tooltip", "readonly", "idFor"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type !== 'hidden')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", {
            class: "InputTextField__wrapper_label",
            for: _ctx.id
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([
						'InputTextField__stateWrapper',
						{ InputTextField__stateWrapper_active: _ctx.active },
						{ InputTextField__stateWrapper_border: _ctx.isValid },
						{ InputTextField__stateWrapper_readonly: !!_ctx.readonlyValue },
						{ InputTextField__stateWrapper_valid: _ctx.isValid && !_ctx.readonlyValue },
						{ InputTextField__stateWrapper_error: _ctx.showError },
						_ctx.helpIconClass,
					])
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass([
								'InputTextField__label',
								{ InputTextField__label_active: _ctx.active },
								{ InputTextField__label_noLabel: !_ctx.placeholder || !!_ctx.readonlyValue },
							]),
                  onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
                  onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
                  onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
                }, _toDisplayString(!!_ctx.readonlyValue ? undefined : _ctx.placeholder), 35),
                _createElementVNode("input", {
                  id: _ctx.id,
                  class: _normalizeClass([
								'InputTextField__input',
								{ InputTextField__input_active: _ctx.active },
								{ InputTextField__helpIcon: !!_ctx.helpIconClass },
							]),
                  value: _ctx.readonlyValue || _ctx.htmlInputValue,
                  readonly: !!_ctx.readonlyValue,
                  onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
                  onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
                  onFocus: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
                }, null, 42, _hoisted_5)
              ])
            ], 2)
          ], 8, _hoisted_3),
          (_ctx.showError)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                role: "alert",
                class: "InputTextField__error",
                textContent: _toDisplayString(_ctx.validationErrorFormatText)
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}