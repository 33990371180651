import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-left DatePickerContainer" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "InputTextField__container" }
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelComponent = _resolveComponent("LabelComponent")!
  const _component_datepicker = _resolveComponent("datepicker")!
  const _component_inputFieldComponent = _resolveComponent("inputFieldComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LabelComponent, {
        label: _ctx.label,
        tooltip: _ctx.tooltip,
        isHtmlLabel: true,
        readonly: !!_ctx.readonlyValue,
        idFor: _ctx.innerId
      }, null, 8, ["label", "tooltip", "readonly", "idFor"]),
      _withDirectives(_createElementVNode("div", {
        class: "InputTextField__wrapper",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setActive && _ctx.setActive(...args))),
        onOntouchstart: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setActive && _ctx.setActive(...args))),
        onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.setActive && _ctx.setActive(...args))),
        tabindex: "0"
      }, [
        _createElementVNode("label", {
          class: "InputTextField__wrapper_label",
          for: _ctx.innerId
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([
							'InputTextField__stateWrapper InputTextField__stateWrapper_calendar',
							{ InputTextField__stateWrapper_active: _ctx.active },
							{ 'InputTextField__stateWrapper_border InputTextField__stateWrapper_valid': _ctx.isValid },
							{ InputTextField__stateWrapper_error: _ctx.showError },
						])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: _normalizeClass([
									'InputTextField__label InputTextField__helpIcon',
									{ InputTextField__label_active: _ctx.active },
									{ InputTextField__label_noLabel: !_ctx.placeholder },
									{ InputTextField__label_hasValue: _ctx.isValid },
								])
              }, _toDisplayString(_ctx.placeholder), 3),
              _createVNode(_component_datepicker, {
                class: _normalizeClass([
									'InputTextField__input',
									{ InputTextField__input_active: _ctx.active },
									'InputTextField__helpIcon',
								]),
                ref: "datePicker",
                modelValue: _ctx.pickedDate,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pickedDate) = $event)),
                  _ctx.change
                ],
                id: _ctx.innerId,
                ___placeholder: "placeholder",
                inputFormat: _ctx.format,
                locale: _ctx.locale,
                typeable: true,
                lowerLimit: _ctx.lowerLimit ? _ctx.lowerLimit : undefined,
                upperLimit: _ctx.upperLimit ? _ctx.upperLimit : undefined,
                onBlur: _ctx.insureCorrectFormat,
                onFocus: _ctx.reset,
                onKeyup: _ctx.insureCorrectFormat
              }, null, 8, ["class", "modelValue", "id", "inputFormat", "locale", "lowerLimit", "upperLimit", "onUpdate:modelValue", "onBlur", "onFocus", "onKeyup"])
            ])
          ], 2)
        ], 8, _hoisted_2)
      ], 544), [
        [_vShow, !_ctx.readonlyValue]
      ]),
      (!!_ctx.readonlyValue)
        ? (_openBlock(), _createBlock(_component_inputFieldComponent, {
            key: 0,
            id: _ctx.innerId,
            helpIconClass: "InputTextField__stateWrapper_calendar",
            ___placeholder: _ctx.placeholder,
            readonlyValue: _ctx.readonlyValueDk
          }, null, 8, ["id", "___placeholder", "readonlyValue"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          role: "alert",
          class: "SearchContainer__error",
          textContent: _toDisplayString(_ctx.errorMsg)
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}