import { CmsField, DigitalAcceptResponse, PaymentResponse, PolicyResponse } from '@/types/types';
import store from '@/store/store';
import { formatDateString } from './FormatService';
import { INPUT_TYPES, POLICY_FIELDS, PROPOSAL_STEPS } from './FieldService';

const ignoreFieldsInUpdateInsuranceObject: string[] = [POLICY_FIELDS.cover_start_date, POLICY_FIELDS.previous_company_id, POLICY_FIELDS.previous_policy_id, POLICY_FIELDS.takeover_date];

export const createDigitalAcceptSubmitRequest = () => {

	const paymentResponse = createPaymentResponse();

	const policies = createPolicies(paymentResponse);

	const digitalAcceptResponse: DigitalAcceptResponse = {
		policies,
		party: {
			payment: paymentResponse,
		},
	};

	return digitalAcceptResponse;
};

const createPaymentResponse = (): PaymentResponse => {
	const paymentBlockFields = store.state.ProposalContext.proposalSteps.get(PROPOSAL_STEPS.paymentBlock)?.inputFields;

	const frequency: string = getFieldValue(paymentBlockFields, POLICY_FIELDS.payment_frequency);

	return {
		payment_method: frequency === '1' ? 'PBS' : 'Konto',
		payment_frequency: parseInt(frequency),
		// KLK should only be send if payment_method === 'Konto'?
		reg_no: (getFieldValue(paymentBlockFields, POLICY_FIELDS.reg_no)),
		account: parseInt(getFieldValue(paymentBlockFields, POLICY_FIELDS.account)),
	};

};


const createPolicies = (paymentResponse: PaymentResponse): Array<PolicyResponse> => {
	const policies: Array<PolicyResponse> = [];

	const policyIds = store.state.ProposalContext.originalRequest.policies.map((policy) => policy.id);
	policyIds.forEach((policyId) => {
		const policy = store.state.ProposalContext.proposalSteps.get(policyId);

		const fields = policy.inputFields;
		const termination = getFieldValue(fields, POLICY_FIELDS.cancelExistingPolicy, true)
			? {
					previous_company_id: getFieldValue(fields, POLICY_FIELDS.previous_company_id),
					previous_policy_id: getFieldValue(fields, POLICY_FIELDS.previous_policy_id),
					takeover_date: getFieldValueDate(fields, POLICY_FIELDS.takeover_date),
			  }
			: null;

		const policyResponse: PolicyResponse = {
			id: policyId,
			cover_start_date: getFieldValueDate(fields, POLICY_FIELDS.cover_start_date),
			update_insurance_object: {
				insurance_type: policy.type.toUpperCase(),
				...getSubmitToApiInsuranceFields(policy.inputFields),
			},
			payment: paymentResponse,
			termination,
		};
		policies.push(policyResponse);
	});
	return policies;
	
};

const getFieldValue = (fields: Array<any>, fieldId: string, ignoreSubmitToApi: boolean = false) => {
	return fields.find((field) => (ignoreSubmitToApi || field.submitToApi) && field.id === fieldId)?.value;
};


const getFieldValueDate = (fields: Array<any>, fieldId: string) => {
	return formatDateString(fields.find((field) => field.submitToApi && field.id === fieldId)?.value, false);
};

const getSubmitToApiInsuranceFields = (inputFields: Array<CmsField>) => {
	const result = {};
	inputFields.forEach((field) => {
		if (field.submitToApi && !ignoreFieldsInUpdateInsuranceObject.includes(field.id)) {
			result[field.id] = getFieldTypeValueFormatted(field);
		}
	});
	return result;
};

const getFieldTypeValueFormatted = (field: CmsField) => {
	switch (field.contentType) {
		case INPUT_TYPES.TextField:
			return field.type === 'number' ? parseInt(field.value as string) : field.value;
		case INPUT_TYPES.DatePickerField:
			return formatDateString(field.value as string, false);
		default:
			return field.value;
	}
};
