import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import store from '@/store/store';
import { ContactBoxBlock } from '@/cms/definitions/content-types';
import { trackContact } from '@/services/TrackService';

@Options({
	name: 'ContactComponent',
	components: {},
	props: {
		model: Object as PropType<ContactBoxBlock>,
	},
})
export default class ContactComponent extends Vue {
	model: ContactBoxBlock;

	public openModal(call: boolean) {
		store.state.modal = this.prepareModal(call);
		trackContact();
	}

	private prepareModal(call: boolean) {
		return {
			icon: call ? this.model.callIcon.url : this.model.emailIcon.url,
			track: false,
			content: call ? this.model.callPopupText : this.model.emailPopupText,
			btnStyle: 'Button Button__tertiary',
			btnLabel: call ? this.model.callPopupBtnText : this.model.emailPopupBtnText,
			title: call ? this.model.callPopupTitle : this.model.emailPopupTitle,
			id: 'contact_popup',
			show: true,
		};
	}
}
