import store from '@/store/store';
import { nextTick } from 'vue';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'ModalComponent',
	props: {
		id: { type: String, default: '123' },
	},
})
export default class ModalComponent extends Vue {
	id?: string; // use to trigger modal with event, ex. on element
	isActive = false;

	// LIFECYCLE
	created() {
		document.addEventListener('keyup', this.handleKeyup);
		document.addEventListener('keydown', this.handleKeyup);
		window.addEventListener('resize', this.handleResize);
		store.subscribeAction((action, state) => {
			if (action.type === 'toggleModal') {
				if (this.id === action.payload.id) {
					this.toggleModal(action.payload.isActive);
				}
			}
		});
	}

	beforeUnmount() {
		document.removeEventListener('keyup', this.handleKeyup);
		document.removeEventListener('keydown', this.handleKeyup);
		window.removeEventListener('resize', this.handleResize);
	}

	// METHODS
	public toggle(val?: boolean) {
		store.dispatch('toggleModal', { id: this.id, show: val });
	}

	private toggleModal(val?: boolean) {
		val === undefined ? (this.isActive = !this.isActive) : (this.isActive = val);

		if (this.isActive) {
			nextTick(() => {
				this.handleResize(); // position modal
			});
		}
	}

	private handleKeyup(e: any) {
		// make sure key event isn't bubbled to page below
		e.preventDefault();
		e.stopPropagation();

		if (e.keyCode === 27 && this.isActive) {
			// escape
			store.dispatch('toggleModal', { id: this.id, show: false });
		}
	}

	private handleResize() {
		const modalInner = (this as any).$refs.modalContent as HTMLElement;
		if (modalInner) {
			const modalHeight = modalInner.getBoundingClientRect().height;
			const modalMargins =
				parseInt(window.getComputedStyle(modalInner).getPropertyValue('margin-top'), 0) +
				parseInt(window.getComputedStyle(modalInner).getPropertyValue('margin-bottom'), 0);

			if (modalHeight + modalMargins > window.innerHeight) {
				// modal placement in top of viewport
				modalInner.style.cssText = `position:absolute;`;
			} else {
				// set absolute position to prevent content jumping if height is changed
				const offsetTop = (window.innerHeight - modalHeight - modalMargins) / 2;
				modalInner.style.cssText = `position:absolute; top: ${offsetTop}px;`;
			}
		}
	}

	// COMPUTED
	get hasFooterSlot() {
		return !!(this as any).$slots.footer;
	}
}
