import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "usabilla-block" }
const _hoisted_2 = { class: "usabilla-inpage" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["ub-in-page", "ub-in-page-category", "ub-in-page-subcategory", "ub-in-page-item"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", {
        class: "text-center",
        innerHTML: _ctx.model.usabillaTitle
      }, null, 8, _hoisted_3),
      _createElementVNode("div", {
        "ub-in-page": _ctx.model.usabillaId,
        style: {"height":"100%","width":"100%","-webkit-overflow-scrolling":"touch"},
        "ub-in-page-category": _ctx.model.usabillaCategory,
        "ub-in-page-subcategory": _ctx.model.usabillaSubCategory,
        "ub-in-page-item": _ctx.model.usabillaItem
      }, null, 8, _hoisted_4)
    ])
  ]))
}