import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vertical-app vertical-flow"
}
const _hoisted_2 = {
  key: 0,
  style: {"color":"red"}
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "vertical-flow__form__step vertical-flow__form__step--header" }
const _hoisted_5 = { class: "vertical-flow__form__step__title" }
const _hoisted_6 = { class: "vertical-flow__form__step__text vertical-flow__form__step__text--initial" }
const _hoisted_7 = { class: "header-sub-title" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "content" }
const _hoisted_10 = { class: "flex flex__space" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = { class: "content" }
const _hoisted_13 = ["id"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["textContent"]
const _hoisted_16 = { class: "vertical-flow__form__step__text" }
const _hoisted_17 = ["textContent"]
const _hoisted_18 = {
  key: 0,
  class: "vertical-flow__form__step__subtitle"
}
const _hoisted_19 = { class: "subtitle-content" }
const _hoisted_20 = { class: "flex flex__space" }
const _hoisted_21 = {
  key: 0,
  class: "vertical-flow__form__step__accordion text-center"
}
const _hoisted_22 = { class: "vertical-flow__form__step__content" }
const _hoisted_23 = ["onClick", "textContent"]
const _hoisted_24 = {
  key: 1,
  style: {"text-align":"center","width":"100%"},
  class: "mt-5"
}
const _hoisted_25 = { style: {"color":"red","border":"2px solid var(--color-transparent, #00b9ea)","border-radius":"8px","padding":"40px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerticalStepComponent = _resolveComponent("VerticalStepComponent")!

  return (_ctx.renderPage)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.model.mockedDataLoaded)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_2, "Api failed -> Mocked request loaded!"))
          : _createCommentVNode("", true),
        (!_ctx.loadError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.model.offerIdLabel), 1),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.quote_id), 1),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("span", null, [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.model.customerInfoLabel), 1),
                          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.party.name), 1)
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from(_ctx.proposalSteps.values()), (step, inx) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'step' + inx
                }, [
                  _createElementVNode("div", {
                    id: '_' + step.id,
                    class: _normalizeClass([
						'vertical-flow__form__step',
						{ 'vertical-flow__form__step--inactive': !_ctx.isActiveStep(step) },
						{ 'vertical-flow__form__step--validated': _ctx.isValid(step) && !_ctx.isActiveStep(step) },
					])
                  }, [
                    _createElementVNode("div", {
                      class: "vertical-flow__form__step__title",
                      onClick: ($event: any) => (_ctx.gotoStep(step))
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(['vertical-flow__form__step__number', { subtitle: step.description }]),
                        textContent: _toDisplayString(_ctx.isValid(step) && !_ctx.isActiveStep(step) ? '' : inx + 1)
                      }, null, 10, _hoisted_15),
                      _createElementVNode("div", _hoisted_16, [
                        _withDirectives(_createElementVNode("h4", {
                          textContent: _toDisplayString(step.title)
                        }, null, 8, _hoisted_17), [
                          [_vShow, step.title]
                        ]),
                        ((step.description && !_ctx.isActiveStep(step)) || step.description)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                              _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                  _createElementVNode("span", null, _toDisplayString(step.description), 1),
                                  _createElementVNode("span", null, _toDisplayString(step.price), 1)
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ], 8, _hoisted_14),
                    _createVNode(_Transition, { name: "slide-vertical-no-delay-fast" }, {
                      default: _withCtx(() => [
                        (_ctx.isActiveStep(step))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                              _createElementVNode("div", _hoisted_22, [
                                _createVNode(_component_VerticalStepComponent, {
                                  "visible-error": _ctx.visibleError,
                                  step: step
                                }, null, 8, ["visible-error", "step"])
                              ]),
                              _createElementVNode("a", {
                                class: _normalizeClass([
									'Button Button__primary mt-3 mb-4',
									{ Button__primary_disabled_with_submit: !_ctx.isValid(step) },
								]),
                                onClick: ($event: any) => (_ctx.nextStep(step)),
                                textContent: _toDisplayString(step.buttonLabel)
                              }, null, 10, _hoisted_23)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ], 10, _hoisted_13)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 512)), [
        [_vShow, _ctx.showPage]
      ])
    : (_ctx.loadError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.loadError), 1)
        ]))
      : _createCommentVNode("", true)
}