import { isWeb, sendMessage } from '@/services/environmentService';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'pageTitleComponent',
	props: {
		mainTitle: String,
		showTitleInApp: Boolean,
	},
})
export default class PageTitleComponent extends Vue {
	mainTitle: string;
	showTitleInApp: boolean;

	public created() {
		if (this.showTitleInApp) {
			sendMessage({ MAIN_TITLE: this.mainTitle });
		}
	}

	public get showTitle() {
		return (isWeb || this.showTitleInApp) && this.mainTitle;
	}
}
