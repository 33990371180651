import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    ref: _ctx.name + _ctx.id,
    type: "button",
    class: _normalizeClass([_ctx.btnClass || 'btn']),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clicked()))
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createElementVNode("img", {
            src: _ctx.icon,
            class: "btn-icon-left"
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
  ], 2))
}