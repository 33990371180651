import { Vue, Options } from 'vue-class-component';
import { PropType } from 'vue';
import store from '@/store/store';
import { UsabillaInPageBlock } from '@/cms/definitions/content-types';

@Options({
	name: 'usabillaInPageComponent',
	props: {
		model: Object as PropType<UsabillaInPageBlock>,
		headlineClasses: String,
	},
})
export default class UsabillaInPageComponent extends Vue {
	model: UsabillaInPageBlock;
	headlineClasses: string;

	private static waiting = false;
	beforeCreate() {
		store.dispatch('setUsabillaCount');
	}

	mounted() {
		// tell store that one usabilla div is mounted reloadUsabillas(
		store.dispatch('setUsabillaMounted');
		UsabillaInPageComponent.reloadUsabillas(this);
	}

	public static reloadUsabillas(usabillaInPageComponent?: UsabillaInPageComponent) {
		// no usabilla component present
		if (!usabillaInPageComponent) {
			return;
		}
		// check if this is the last mounted usabilla div
		const usabilla = store.getters.getUsabillaState;
		const site = usabillaInPageComponent.model?.siteId ? usabillaInPageComponent.model.siteId : 'e9e9f96ce675';

		if (usabilla.mounted === usabilla.count && (window as any).usabilla) {
			if (UsabillaInPageComponent.waiting) {
				return;
			}
			UsabillaInPageComponent.waiting = true;

			setTimeout(() => {
				const iFrames = document.querySelectorAll('[ub-in-page] iframe') as any;
				if (iFrames?.length && (window as any).usabilla) {
					iFrames.forEach((element) => {
						element.parentNode.removeChild(element);
					});
				}

				(window as any).usabilla.load('w.usabilla.com', site);
				UsabillaInPageComponent.waiting = false;
			}, 1000);
		}
	}
}
