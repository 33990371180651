import { format, parse, isBefore } from 'date-fns';

export const formatter: Intl.NumberFormat = Intl.NumberFormat('da-DK', {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
} as any);

export const formatterDigit: Intl.NumberFormat = Intl.NumberFormat('da-DK', {
	minimumFractionDigits: 0,
	maximumFractionDigits: 2,
} as any);

const daDateFormat = 'dd-MM-yyyy';
const enDateFormat = 'yyyy-MM-dd';

export const formatDateString = (dateString: string, toDanish: boolean): string => {
	try {
		const now = new Date();
		const dkDate = parse(dateString, toDanish ? enDateFormat : daDateFormat, now);
		const v = format(dkDate, toDanish ? daDateFormat : enDateFormat);
		return v;
	} catch (error) {
		return dateString;
	}
};

export const formatDate = (date: Date, dateFormat: string = daDateFormat): string => {
	try {
		const v = format(date, dateFormat);
		return v;
	} catch (error) {
		return undefined;
	}
};

export const isDateUsFormat = (val: string): boolean => {
	return val.indexOf('-') > 3 || val.indexOf('/') > 3 || val.indexOf('.') > 3;
};
export const isDateIsoFormat = (val: string): boolean => {
	// probably 2022-02-01T00:00:00.000Z
	return val.length > 10;
};

export const formatDateIsoOrUsToDanish = (val: string, daFormat: string = daDateFormat): string => {
	// change to DK
	const date = new Date(val);
	return format(date, daFormat);
};

export const getDate = (iso_us_dk: string): Date => {
	if (iso_us_dk) {
		let d = iso_us_dk;
		if (isDateUsFormat(iso_us_dk) || isDateIsoFormat(iso_us_dk)) {
			d = formatDateIsoOrUsToDanish(iso_us_dk);
		}
		return parse(d, daDateFormat, new Date());
	}
};

export const isDateBefore = (dateBefore: Date, dateAfter: Date): boolean => {
	return isBefore(dateBefore, dateAfter);
};
