import { isLocalHost } from '@/services/environmentService';
import { Vue, Options } from 'vue-class-component';

@Options({
	name: 'TestPage',
})
export default class PageComponentSelector extends Vue {
	public carArray = [];
	public travelArray = [];
	public isLocalhost = isLocalHost;

	public mounted(): void {
		this.carArray.push([
			true,
			'9691309470 - Codan customer with Consent call',
			'?quote=0Q03M000000BOO8SAO&customer_no=9691309470&signature=548b52191113c0c4c3a29ef3b64f194723ea65b755dd3cd3612cd0113333a004',
		]);
		this.travelArray.push([
			true,
			'9691309470 - Codan customer with Consent call',
			'?quote=0Q0QJ000000fsHN0AY&customer_no=9691309470&signature=86e5aac385309e97be4f797187ca48a9408f353010972ec644c9da0f64b4e366',
		]);

		this.travelArray.push([
			false,
			'9691309470 - Codan customer receipt',
			'?quote=0Q0QJ000000fsHN0AY&customer_no=9691309470&signature=86e5aac385309e97be4f797187ca48a9408f353010972ec644c9da0f64b4e366',
		]);
	}
}
