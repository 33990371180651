import { ADD_PROPOSAL_INFO, ADD_PROPOSAL_STEPS } from '@/store/modules/ProposalContext';
import store from '@/store/store';
import { cloneDeep } from 'lodash';
import { isDevelop } from '@/services/environmentService';
import { formatDate, formatter } from '@/services/FormatService';
import { CmsField, insuranceCompany, RadioButtonField } from '@/types/types';
import { getCancellationDate, INPUT_TYPES, POLICY_FIELDS, PROPOSAL_STEPS } from '@/services/FieldService';

export default class ModelMapper {
	private readonly proposal: any;
	private readonly cmsModel: any;

	constructor(proposal: any, cmsModel: any) {
		this.proposal = proposal.data;
		this.cmsModel = cmsModel;
		store.dispatch(ADD_PROPOSAL_INFO, proposal.data);
		// Debug ('this.proposal', this.proposal);
		// Debug ('this.cmsModel', this.cmsModel);
	}

	public async mapProposalSteps() {
		const proposalSteps = [];

		// this is the order in which steps are shown
		this.mapPolicies(this.proposal.policies, proposalSteps);
		this.mapPreviousInsuranceStep(proposalSteps);
		this.mapConsentStep(proposalSteps);
		this.mapPaymentStep(proposalSteps);
		await store.dispatch(ADD_PROPOSAL_STEPS, proposalSteps);
	}

	private mapPolicies(policies, proposalSteps) {
		const removeFields = [];
		policies.forEach((policy, inx) => {
			this.flattenProductFields(policy);
			const cmsPolicy = this.getCmsPolicy(policy.type);
			cmsPolicy.id = policy.id;
			let previousFieldId: string;
			if (cmsPolicy.inputFields) {
				cmsPolicy.inputFields.forEach((cmsField) => {
					if (!cmsField.inactive) {
						cmsField.isValid = false;
						const apiField = policy.policy_object.hasOwnProperty(cmsField.id);
						if (apiField) {
							this.mapApiToModel(cmsField, policy.policy_object[cmsField.id]);
						} else {
							// set readonly false if field is not in API
							cmsField.readonly = false;
						}

						this.generalMapField(cmsPolicy, cmsField, previousFieldId, inx);
						previousFieldId = cmsField.id;
						// Debug ('mappedField', cmsField);
					} else {
						console.warn('remove Ignored CMSfield', cmsField.id);
						removeFields.push(cmsField.id);
					}
				});
				cmsPolicy.inputFields = cmsPolicy.inputFields.filter((field) => !removeFields.includes(field.id));
				// navn skal mappes til primær bruger, hvis primær bruger ikke er sat
				// cover_start_date: ligger på product og ikke field
				// termination fields skal mappes
			} else {
				cmsPolicy.inputFields = [];
			}

			policy.title = cmsPolicy.title;

			this.handleCancellation(cmsPolicy.id, cmsPolicy.inputFields);

			if (!policy.description) {
				console.warn('Product Field is not valid policy.description');
				policy.description = 'TODO Product Field is not valid policy.description';
			}

			cmsPolicy.price = `${formatter.format(policy.price)} kr./år`;
			cmsPolicy.description = policy.description;
			cmsPolicy.buttonLabel = this.cmsModel.adjustPoliciesBlock.buttonLabel;

			proposalSteps.push(cmsPolicy);
			//  Debug ('cmsPolicy inputFields', cmsPolicy.inputFields);
			
			if (isDevelop) {
				this.healthCheck(policy, cmsPolicy);
			}
		});
	}

	/**
	 *
	 * @param policy Puts fields in the policy.policy_object Object, that are placed on lower levels
	 * This goes for the field cover_start_date and the policy.termination fields
	 * @returns void
	 */
	private flattenProductFields(policy) {
		// termination fields mapped to field structure
		// "previous_company_id": "00001",
		// "previous_policy_id": "1234567890",
		// "takeover_date": "2024-01-16"

		if (!policy.policy_object) {
			policy.policy_object = {};
		}
		if(policy.termination) {
			const propertyNames = Object.keys(policy.termination);
			propertyNames.forEach((name) => {
				policy.policy_object[name] = policy.termination[name];
			});
	
		}
		//cover_start_date mapped to field
		policy.policy_object.cover_start_date = policy.cover_start_date;
	}
	private healthCheck(policy, cmsPolicy) {
		console.log('HEALTHCHECK - DEVELOP');
		const onlyCmsFields = [POLICY_FIELDS.cancelExistingPolicy, POLICY_FIELDS.cancelExistingPolicyOptions, POLICY_FIELDS.cancelExistingPolicyText];
		// Debug ('policy', policy);
		// Debug ('cmsPolicy', cmsPolicy);
		let match = undefined;
		console.log('Testing CMS fields ******************************');
		cmsPolicy.inputFields.forEach((cmsField) => {
			match = policy.policy_object.hasOwnProperty(cmsField.id);
			if (!match) {
				match = onlyCmsFields.find((fieldId) => fieldId === cmsField.id);
				if (!match) {
					console.log(
						'Cms field not in product',
						cmsField.id,
						cmsField,
						'policy',
						policy.policy_object[cmsField.id]
					);
				}
			}
		});
		console.log('Testing Product fields ******************************');

		for (const property in policy.policy_object) {
			const match = cmsPolicy.inputFields.find((cmsField) => cmsField.id === property);
			if (!match) {
				console.log('Product field not in cms', property);
			}
		}

		console.log('END HEALTHCHECK - DEVELOP ******************************');
	}

	private handleCancellation (stepId: string, inputFields: CmsField[]) {
		const coverStart = getCancellationDate(stepId, inputFields);
		if(coverStart) {
			const cancellationOptions = inputFields.find(
				(field) => field.id === POLICY_FIELDS.cancelExistingPolicyOptions
			) as RadioButtonField;

			cancellationOptions.labels[0] += ` - ${formatDate(coverStart)}`;
		}

	}

	private mapPreviousInsuranceStep(proposalSteps: Array<any>): void {
		const step = this.mapStep(
			this.cmsModel.previousInsuranceBlock,
			PROPOSAL_STEPS.previousBlock,
			this.cmsModel.previousInsuranceBlock.buttonLabel
		);
		proposalSteps.push(step);
	}

	private mapConsentStep(proposalSteps: Array<any>): void {
		const step = this.mapStep(this.cmsModel.consentsBlock, PROPOSAL_STEPS.consentBlock, this.cmsModel.consentsBlock.buttonLabel);
		proposalSteps.push(step);
	}

	private mapPaymentStep(proposalSteps: Array<any>): void {
		const step = this.mapStep(
			this.cmsModel.paymentMethodBlock,
			PROPOSAL_STEPS.paymentBlock,
			this.cmsModel.paymentMethodBlock.buttonLabel
		);

		// map payment fields values from this.proposal.party.payment values ;
		step.inputFields.forEach((cmsField) => {
			const apiField = this.proposal.party.payment.hasOwnProperty(cmsField.id);

			if (apiField) {
				this.mapApiToModel(cmsField, this.proposal.party.payment[cmsField.id]);
			}
		});

		this.mapProductPriceBlock(step);
		proposalSteps.push(step);
	}

	public getReceiptPolicyDetails(): any {
		const step = this.mapStep(this.cmsModel, PROPOSAL_STEPS.receiptBlock, this.cmsModel.buttonLabel);
		this.mapProductPriceBlock(step);
		return step;
	}

	private mapProductPriceBlock(step) {
		const policies = this.proposal.policies;
		const productHighlights = [];

		policies.forEach((policy, inx) => {
			if (!policy.description) {
				console.warn('Product Field is not valid policy.description');
			}
			
			const policyEntry = `<div style="margin-bottom: -24px;"><span class="LabelComponent">${policy.title}</span><br/>
            <div class="flex flex__space">
             <span>${policy.description}</span> <span>${formatter.format(policy.price)} kr./år</span>
            </div>
            </div>
			${inx === policies.length -1 ? '<div class="border-bottom"></div>' : ''}`;

			const uiField = {
				id: policy.title + inx,
				uiId: policy.title + inx,
				value: policyEntry,
				contentType: INPUT_TYPES.TextOnlyField,
				isVisible: true,
				isValid: true,
			};
			productHighlights.push(uiField);
			
		});
		step.inputFields = [...productHighlights, ...step.inputFields];

		const price = step.inputFields.find((field) => field.id === POLICY_FIELDS.price);
		const txt = `
            <div class="flex flex__space mt-n5" style="margin-bottom: -48px;">
            <span class="mt-2 LabelComponent">${this.replaceHtmlParagraph(price.value)}</span> 
            <span class="LabelComponent">${formatter.format(
				this.proposal.price
			)} kr./år</span>
            </div>`;
		price.value = txt;
		price.uiId = price.id;
		price.isVisible = true;
		price.isValid = true;

		const fees = step.inputFields.find((field) => field.id === POLICY_FIELDS.fees);
		fees.value =
			this.replaceHtmlParagraph(fees.value) + ' ' + formatter.format(this.proposal.fees) + ' kr. <br/><br/>';
		fees.isVisible = true;
		fees.isValid = true;

		
		//consent_payment
		const consent = step.inputFields.find((field) => field.id === POLICY_FIELDS.consent_payment);
		// not present on receipt
		if (consent) {
			consent.postLabel = consent.postLabel.replaceAll(/{{offerId}}/ig, store.state.ProposalContext.quote_id);
		}
		
		
	}

	private generalMapField(cmsPolicy, cmsField, previousFieldId, inx) {
		cmsField.uiId = cmsField.id + inx; // unique id for html element

		if (cmsField.isDependentPrevious && previousFieldId) {
			cmsField.dependentId = previousFieldId;
			// Debug ('mapping field', cmsField.id);
			// Debug ('field', cmsField);
		}

		if (cmsField.labels || cmsField.options) {
			if (cmsField.labels) {
				cmsField.labels = cmsField.labels.split('\n').filter((elem) => elem !== '');
			}

			if (cmsField.options) {
				cmsField.options = cmsField.options.split('\n').filter((elem) => elem !== '');
			}

			if (!cmsField.labels) {
				cmsField.labels = [...cmsField.options];
			}

			if (!cmsField.options) {
				cmsField.options = [...cmsField.labels];
			}

			this.handleInsuranceCompanies(cmsPolicy, cmsField);

			if (cmsField.labels.length !== cmsField.options.length) {
				console.warn(
					'List labels and options differ in length -> id',
					cmsField.id,
					'Labels length;',
					cmsField.labels.length,
					'Options length:',
					cmsField.options.length
				);
			}
			// DEBUG if (cmsField.id === 'yearly_mileage')
			// Debug ('HIT LABELS', cmsField.id ,cmsField.labels, cmsField.options);
		}

		if (cmsField.contentType === INPUT_TYPES.CheckField) {
			cmsField.isValid = !cmsField.isRequired;
			if (cmsField.masterId) {
				cmsField.class = cmsField.class ? cmsField.class + ' checkbox-child' : 'checkbox-child';
				cmsPolicy.hasMasterChildCheckFields = true;
			}
		}

		// replace Optimizely inserted <p> with <span>
		if (cmsField.contentType === INPUT_TYPES.TextOnlyField) {
			cmsField.isValid = true;
			if (cmsField.value) {
				cmsField.value = this.replaceHtmlParagraph(cmsField.value);
			}
		}

		if (cmsField.contentType === INPUT_TYPES.NumberField) {
			cmsField.contentType = INPUT_TYPES.TextField;
			cmsField.type = 'number';
		}

		if (cmsField.tooltip.includes('<p>')) {
			cmsField.tooltip = this.replaceHtmlParagraph(cmsField.tooltip);
		}
	}

	private handleInsuranceCompanies(cmsPolicy, cmsField) {
		if (cmsField.id === POLICY_FIELDS.previous_company_id) {
			const insuranceCompanies = store.state.ProposalContext.insuranceCompanies;
			if (insuranceCompanies && insuranceCompanies?.length > 0) {
				cmsField.labels = [];
				cmsField.options = [];
				insuranceCompanies.forEach((company) => {
					if (this.isValidCompanyForProduct(cmsPolicy.type, company)) {
						cmsField.labels.push(company.company_name);
						cmsField.options.push(company.company_id + '');
					}
				});
			}
		}
	}

	private isValidCompanyForProduct(productType, company: insuranceCompany) {
		const typeUpper = productType.toLocaleUpperCase();
		switch (typeUpper) {
			case 'CAR':
				return company.is_auto_insurer;
			case 'ULYKKE':
				return company.is_accident_insurer;
			default:
				return true;
		}
	}

	private replaceHtmlParagraph(htmlValue: string) {
		return htmlValue.replaceAll('<p>', '<span>').replaceAll('</p>', '</span>');
	}

	private mapStep(cmsBlock, blockId, buttonLabel) {
		const step: any = {};
		step.title = cmsBlock.stepHeadline;
		this.simplifyInputFields(cmsBlock);

		let previousFieldId: string;
		const removeFields = [];

		cmsBlock.inputFields.forEach((field, inx) => {
			if (!field.inactive) {
				this.generalMapField(cmsBlock, field, previousFieldId, inx);
				previousFieldId = field.id;
			} else {
				console.warn('remove Ignored CMSfield', field.id);
				removeFields.push(field.id);
			}
		});
		cmsBlock.inputFields = cmsBlock.inputFields.filter((field) => !removeFields.includes(field.id));

		step.inputFields = cmsBlock.inputFields;
		step.hasMasterChildCheckFields = cmsBlock.hasMasterChildCheckFields;

		// missing
		step.id = blockId;
		step.buttonLabel = buttonLabel;
		return step;
	}

	private mapApiToModel(cmsField, apiFieldValue) {
		// debug if (cmsField.id === 'license_plate') {
		// 	debug ('cmsField.value', cmsField.value);
		// 	debug ('apiField.value', apiFieldValue);
		// }
		const hasValue = this.hasValue(apiFieldValue);
		// debug if (cmsField.id === 'license_plate') {
		// 	debug ('hasValue', hasValue);
		// }
		cmsField.isValid = hasValue;

		if (hasValue) {
			cmsField.value = apiFieldValue + '';
		}

		// sanity check
		if (cmsField.readonly && !hasValue) {
			// if readonly and no value
			cmsField.readonly = false;
			cmsField.isValid = false;
		}
	}

	private hasValue(value): boolean {
		if (value === undefined || value === null || value === 'null' || value === 'undefined') {
			return false;
		} else if ((value + '').trim() === '') {
			return false;
		}
		return true;
	}

	private getCmsPolicy(policyType: string) {
		const cmsPolicy = this.cmsModel.adjustPoliciesBlock.policyBlock.items.find((policyBlock) => {
			if (policyBlock.content.type.toLocaleLowerCase() === policyType.toLocaleLowerCase()) {
				return policyBlock;
			}
		});
		// always work on copy of content structure
		const policy = cloneDeep(cmsPolicy.content);
		this.simplifyInputFields(policy);
		return policy;
	}

	// remove api layers
	private simplifyInputFields(step) {
		const inputFields = [];
		step.inputFields.items.forEach((field) => {
			inputFields.push(field.content);
		});
		step.inputFields = inputFields || [];
	}

}
