import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page__title"
}
const _hoisted_2 = { class: "display-codan page__header" }
const _hoisted_3 = { class: "display-ab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showTitle)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.mainTitle), 1),
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.mainTitle), 1)
      ]))
    : _createCommentVNode("", true)
}