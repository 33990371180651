import getPolicyProposalHardCodedCarTravelBoat from './getPolicyProposalHardCodedCarTravelBoat.json';
import getPolicyProposalHardCodedTravel5 from './getPolicyProposalHardCodedTravel5.json';
import getPolicyProposalHardCoded4 from './getPolicyProposalHardCoded4.json';
import getReceiptHardCoded from './getReceiptHardCoded.json';
import dogList from './dogList.json';
import dogListIds from './dogListIds.json';

export default class TestConfig {
	public static getDogList() {
		return dogList;
	}

	public static getDogListIds() {
		return dogListIds;
		// DEBUG ('testJson', JSON.stringify(data));
	}

	// public static getInsuranceCompanies() {
	//     return ["Agria Dyreforsikring","AIG","Alka","Allianz","Alm. Brand A/S","Alpha Insurance","Aros Forsikring","Axa Danmark","Bauta Forsikring A/S","Bornholms Brandforsikring","Byg Forsikringsservice A/S","Chubb European Group Limited","Codan Forsikring A/S","Concordia Forsikring A/S","Coop for Topdanmark Forsikring","DFA for ETU Forsikring","ETU Forsikring A/S","Euro Insurance Ltd.","Europæiske Rejseforsikring","First for QIC Europe Ltd.","Focus Forsikringsagtentur for INSR","Fokus Forsikring A/S","Gartnernes Forsikring","Gefion Insurance A/S","Gf-Forsikring A/S","Gjensidige Forsikring","Global for Insr Insurance Group","Global for Sønderjysk Forsikring","Gouda for Gjensidige Forsikring","Greenval Insurance Company Ltd.","HD Forsikring Assurance Agentur","HDI Danmark","Hf-Forsikring G/S","Himmerland Forsikring Gs","If P&C Insurance v. Watercircles","If Skadesforsikring","Insr Insurance Group ASA","Købstædernes Alm.Brand","LB-PFA for Lærerstandens Brandfors.","LLOYD'S/QBE","Lloyds 1886 v. QBE Danmark A/S","Lloyds v/First","Lloyds v/Indigo Underwriters","Lloyds XL Catlin v/Baltic Finance","Lokal Forsikring","Lærerstandens Brandforsikring G","Mondo for Insur Insurance Group","Morsø Forsikring","Mølholm for Gjensidige Forsikring","Nem Forsikring A/S","Nykredit for Gjensidige Forsikring","Nærsikring","Pantaenius","PenSam Forsikring A/S","Popermo G/S","Privatsikring A/S","Prosam forsikringsagentur for Insr","Protector","QBE DANMARK (Belgien)","Qudos Insurance A/S","Risk Point A/S","Runa Forsikring A/S","Stevns Brand G/S","Storstrøms Forsikring G/S","Søassuranceforeningen Ærø G/S","Søassurancen Danmark","Sønderjysk Forsikring A/S","Thisted Amt - Forsikring","Topdanmark Forsikring A/S","Topdanmark Livsforsikring","Trafik","Tryg Forsikring","TRYGD","Tryggingarfelagid Føroyar","Vejle Brand Af 1841","Vendsyssel Forsikring G/S","Vestjylland G/S","Viking for ETU Forsikring","Watercircles Forsikring ASA","Wintherthur International","Zürich Danmark"];
	// }

	// public static getInsuranceCompaniesIds() {
	//     return ["831","15","1","830","73","105","6","13","77","87","804","89","70","93","835","123","101","61","816","133","125","54","88","116","64","106","124","137","827","112","837","119","75","68","111","32","115","95","136","19","107","102","118","832","86","33","834","811","836","35","96","817","803","109","79","51","127","110","135","113","818","30","805","4","820","833","80","71","34","829","55","8","823","826","92","72","76","122","120","63","104"];
	// }

	public static getReceiptHardCoded() {
		return getReceiptHardCoded;
	}

	public static getPolicyProposalHardCoded4() {
		return getPolicyProposalHardCoded4;
	}

	public static getPolicyProposalHardCodedTravel5() {
		return getPolicyProposalHardCodedTravel5;
	}

	public static getPolicyProposalHardCodedCarTravelBoat() {
		return getPolicyProposalHardCodedCarTravelBoat;
	}

	public static getPolicyConsentHardCoded() {
		return {
			status: 'TEST_DATA',
			data: {
				consents: [
					{
						consent_type: 'PROMOTIONPHONE',
						is_accepted: false,
						consent_text: 'Mocked phone consent',
					},
					{
						consent_type: 'PROMOTIONLETTER',
						is_accepted: true,
						consent_text: 'Mocked letter consent',
					},
					{
						consent_type: 'PROMOTIONMITALMBRAND',
						is_accepted: false,
						consent_text: 'Mocked Mit Codan consent',
					},
					{
						consent_type: 'PROMOTIONSMS',
						is_accepted: true,
						consent_text: 'Mocked SMS consent',
					},
					{
						consent_type: 'PROMOTIONEMAIL',
						is_accepted: true,
						consent_text: 'Mocked email consent',
					},
				],
			},
		};
	}
}
