import { AcceptPage } from '@/cms/definitions/content-types';
import DigitalAcceptComponent from '@/views/digitalAccept/DigitalAcceptComponent.vue';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import store from '@/store/store';
import { setPageMetaData } from '@/services/environmentService';
import { PROPOSAL_LOADED } from '@/store/modules/ProposalContext';
import { initialPageTrack } from '@/services/TrackService';

@Options({
	name: 'AcceptPageComponent',
	components: {
		DigitalAcceptComponent,
	},
	props: {
		model: Object as PropType<AcceptPage>,
	},
})
export default class AcceptPageComponent extends Vue {
	model: AcceptPage;
	public showSubtextSpinner = true;
	beforeMount() {
		setPageMetaData(this.model);

		store.subscribeAction((action, state) => {
			if (action.type === PROPOSAL_LOADED) {
				setTimeout(() => {
					this.showSubtextSpinner = false;
				}, 100);
			}
		});
	}

	mounted() {
		initialPageTrack();
	}

	public get showSpinner() {
		return store.state.showSpinner;
	}
}
