import { Log } from '@/appinsights/logging/log';
import { ADD_GA4_IMMEDIATE_EVENT, ADD_GA4_PAGE_EVENTS, FIRE_GA4_EVENTS } from '@/store/modules/trackingContext';
import store from '@/store/store';

export const dataLayer = (window as any).dataLayer || [];

export const trackGeneralPageView = async (from: string, to: string, pathName: string): Promise<void> => {
	try {
		const event = {
			pageSection: pathName,
			pagePlatform: 'alm. brand',
			previousPageUrl: from,
			fullUrl: to,
		};

		const event1 = {
			event: 'trackPageview',
		};
		await store.dispatch(ADD_GA4_PAGE_EVENTS, [event, event1]);
	} catch (e) {
		// too bad
		Log.exception('pageTrack', e, { err: e.toString() });
	}
};

export const trackStep = async (productName: string, stepName: string): Promise<void> => {
	const event = {
		event: 'trackStepProgress',
		productName,
		stepName,
		offerId: store.state.ProposalContext.quote_id,
	};
	await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event);
};

export const trackContact = async (): Promise<void> => {
	const event = {
		event: 'trackContact',
		offerId: store.state.ProposalContext.quote_id,
	};
	await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event);
};

export const trackError = async (errorType: string, errorCode = 'NA'): Promise<void> => {
	try {
		const event = {
			event: 'trackError',
			errorType,
			errorCode,
			offerId: store.state.ProposalContext.quote_id,
		};
		await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event);
	} catch (e) {
		// too bad
		Log.exception('errorTrack', e, { err: e.toString() });
	}
};

export const initialPageTrack = () => {
	// only relevant on direct pageLoad
	setTimeout(() => {
		if (!store.state.trackingContext.isFirstPageEventFired) {
			Log.information('FirstPageLoad Fired From code');
			fireTrackingEvents();
		}
	}, 1500);
};

/***** private methods *****************/

const fireTrackingEvents = async () => {
	await trackGeneralPageView(store.state.trackingContext.lastUrl, location.href, location.pathname);
	store.state.trackingContext.lastUrl = location.href;
	await store.dispatch(FIRE_GA4_EVENTS);
};

// Private methods
// KLK const eventReset = { ecommerce: null };
